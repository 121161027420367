<template>
  <div class="card">
    <div class="card-header">
      <div v-if="type === 'systemOwner'" class="card-header-title">
        {{ $t("system_owner_change") }}
      </div>
      <div v-if="type === 'assignedTo'" class="card-header-title">
        {{ $t("assigned_to_change") }}
      </div>
    </div>
    <div class="card-content">
      <form class="mt-2" @submit.prevent="submit">
        <CompanySelect
          v-model="company"
          :label="$tc('company')"
          :companies="companies"
          :horizontal="true"
          :loading="loading"
        ></CompanySelect>

        <b-button
          type="is-primary"
          native-type="submit"
          class="mt-5"
          icon-left="content-save"
          :loading="submitting"
        >
          {{ $t("save") }}
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import CompanySelect from "../../../components/forms/CompanySelect";

export default {
  components: { CompanySelect },
  props: {
    device: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      submitting: false,
      company: this.device[this.type]?.["@id"],
      companies: [],
    };
  },
  mounted() {
    this.getAllCompanies({ blocked: false }).then((companies) => {
      this.companies = companies;
      this.loading = false;
    });
  },
  methods: {
    ...mapActions(["getAllCompanies", "updateDevice"]),
    submit() {
      this.submitting = true;
      this.updateDevice({
        id: this.device.id,
        device: {
          [this.type]: this.company,
        },
      })
        .then(() => {
          this.$emit("submit");
          this.$parent.close();
        })
        .catch(() => {
          this.submitting = false;
        });
    },
  },
};
</script>
