<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ $t("upload_certificate") }}
      </div>
    </div>
    <div class="card-content">
      <form class="mt-2" @submit.prevent="submit">
        <b-field
          class="file"
          :class="{ 'has-name': !!file }"
          :message="invalid ? $t('certificate_filename_validation') : ''"
          :type="invalid ? 'is-danger' : ''"
        >
          <b-upload
            id="file"
            v-model="file"
            class="file-label"
            accept="application/pdf"
          >
            <span class="file-cta">
              <b-icon class="file-icon" icon="file"></b-icon>
              <span class="file-label">
                {{ file ? file.name : $t("select_file") }}
              </span>
            </span>
          </b-upload>
        </b-field>

        <b-button
          type="is-primary"
          native-type="submit"
          class="mt-2"
          icon-left="upload"
          :disabled="!file || invalid"
          :loading="submitting"
        >
          {{ $t("upload") }}
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      file: null,
      invalid: false,
    };
  },
  watch: {
    file(value) {
      const suffix = this.device.serialNumber.substr(-3);
      this.invalid = !value.name.includes(suffix);
    },
  },
  methods: {
    ...mapActions(["uploadDeviceCalibrationCertificate"]),
    submit() {
      this.submitting = true;

      const payload = new FormData();
      payload.append("file", this.file, this.file.name);

      this.uploadDeviceCalibrationCertificate({
        id: this.device.id,
        payload,
      })
        .then(() => {
          this.$emit("submit");
          this.$parent.close();
        })
        .catch(() => {
          this.submitting = false;
        });
    },
  },
};
</script>
