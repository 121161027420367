<template>
  <div>
    <b-loading v-model="loading"></b-loading>

    <div
      v-if="!loading"
      class="is-flex is-flex-wrap-wrap is-align-items-center my-4"
    >
      <h1 class="mr-5">
        <span>{{ $tc("device", 2) }} &ndash;</span>
        {{ device.serialNumber }}
      </h1>
      <b-button
        type="is-primary"
        icon-left="pencil"
        tag="router-link"
        :to="{ name: 'DeviceEdit', params: { id: $route.params.id } }"
      >
        {{ $t("edit") }}
      </b-button>
    </div>

    <div v-if="!loading" class="card">
      <div class="card-content pt-3">
        <BackButton
          :parent-route="{ name: 'DeviceIndex', query: { tab: 'devices' } }"
        ></BackButton>

        <table class="table details-table">
          <tr v-if="userHasRoleSuperAdmin">
            <th>{{ $t("status") }}</th>
            <td>
              {{ device.status.name }}<br />
              <b-button
                icon-left="swap-horizontal"
                class="mt-1"
                @click="showDeviceStatusModal = true"
              >
                {{ $t("status_change") }}
              </b-button>
            </td>
          </tr>
          <tr>
            <th>{{ $tc("product_type") }}</th>
            <td>{{ device.product.name }}</td>
          </tr>
          <tr>
            <th>{{ $t("serial_number") }}</th>
            <td>{{ device.serialNumber }}</td>
          </tr>
          <tr v-if="userHasRoleSuperAdmin">
            <th>{{ $t("manufacturing_date") }}</th>
            <td>{{ device.manufacturingDate | date }}</td>
          </tr>
          <tr v-if="userHasRoleSuperAdmin">
            <th>{{ $t("manufacturing_costs") }}</th>
            <td>{{ device.manufacturingCosts | money }}</td>
          </tr>
          <tr>
            <th>{{ $t("delivery_date") }}</th>
            <td>{{ device.deliveryDate | date }}</td>
          </tr>
          <tr>
            <th>{{ $t("calibration") }}</th>
            <td>
              <p v-if="device.lastCalibrationDate">
                {{ $t("calibrated") }} {{ device.lastCalibrationDate | date }}
              </p>
              <p v-if="device.calibrationExpirationDate" class="mb-2">
                {{ $t("expires") }}
                {{ device.calibrationExpirationDate | date }}
              </p>
              <b-button
                v-if="calibrationCertificateUrl"
                icon-left="download"
                class="mt-1 mr-2"
                tag="a"
                download
                :href="calibrationCertificateUrl"
              >
                {{ $t("download_certificate") }}
              </b-button>
              <b-button
                v-if="userHasRoleSuperAdmin"
                icon-left="upload"
                class="mt-1"
                @click="showCertificateModal = true"
              >
                {{ $t("upload_certificate") }}
              </b-button>
            </td>
          </tr>
          <tr>
            <th>{{ $t("system_owner") }}</th>
            <td v-if="!userHasRoleSuperAdmin">
              {{ device.systemOwner.name }}
            </td>
            <td v-if="userHasRoleSuperAdmin">
              <router-link
                v-if="device.systemOwner"
                :to="{
                  name: 'CompanyView',
                  params: { id: device.systemOwner.id },
                }"
              >
                {{ device.systemOwner.name }}
              </router-link>
              <br />
              <b-button
                icon-left="swap-horizontal"
                class="mt-1"
                @click="showSystemOwnerModal = true"
              >
                {{ $t("system_owner_change") }}
              </b-button>
            </td>
          </tr>
          <tr v-if="userHasRoleSuperAdmin">
            <th>{{ $t("assigned_to") }}</th>
            <td>
              <router-link
                v-if="device.assignedTo"
                :to="{
                  name: 'CompanyView',
                  params: { id: device.assignedTo.id },
                }"
              >
                {{ device.assignedTo.name }}
              </router-link>
              <br />
              <b-button
                v-if="!device.activeContract"
                icon-left="swap-horizontal"
                class="mt-1"
                @click="showAssignedToModal = true"
              >
                {{ $t("assigned_to_change") }}
              </b-button>
            </td>
          </tr>
          <tr>
            <th>{{ $t("contract_active") }}</th>
            <td>
              <router-link
                v-if="device.activeContract"
                :to="{
                  name: 'ContractView',
                  params: { id: device.activeContract.id },
                }"
              >
                {{ device.activeContract.company.name }}
                ({{ device.activeContract.startDate | date }} &ndash;
                {{ device.activeContract.endDate | date }})
                <br />
              </router-link>
            </td>
          </tr>
          <tr>
            <th>{{ $t("xt_software_version") }}</th>
            <td>{{ device.xtSoftwareVersion }}</td>
          </tr>
          <tr>
            <th>{{ $t("xt_software_password") }}</th>
            <td>
              {{ device.xtSoftwarePassword }}
            </td>
          </tr>
          <tr v-if="userHasRoleSuperAdmin">
            <th>{{ $t("created") }}</th>
            <td>
              {{ device.createdAt | dateTime }}
            </td>
          </tr>
          <tr v-if="userHasRoleSuperAdmin">
            <th>{{ $t("updated") }}</th>
            <td>
              {{ device.updatedAt | dateTime }}
            </td>
          </tr>
        </table>

        <Services v-if="userHasRoleSuperAdmin" :device="device"></Services>
      </div>
    </div>

    <b-modal
      v-if="userHasRoleSuperAdmin"
      v-model="showDeviceStatusModal"
      :width="400"
      scroll="keep"
    >
      <DeviceStatusModal :device="device" @submit="load"></DeviceStatusModal>
    </b-modal>

    <b-modal
      v-if="userHasRoleSuperAdmin"
      v-model="showCertificateModal"
      :width="400"
      scroll="keep"
    >
      <DeviceCertificateModal
        :device="device"
        @submit="load"
      ></DeviceCertificateModal>
    </b-modal>

    <b-modal
      v-if="userHasRoleSuperAdmin"
      v-model="showSystemOwnerModal"
      :width="400"
      scroll="keep"
    >
      <DeviceCompanyModal
        :device="device"
        :type="'systemOwner'"
        @submit="load"
      ></DeviceCompanyModal>
    </b-modal>

    <b-modal
      v-if="userHasRoleSuperAdmin"
      v-model="showAssignedToModal"
      :width="400"
      scroll="keep"
    >
      <DeviceCompanyModal
        :device="device"
        :type="'assignedTo'"
        @submit="load"
      ></DeviceCompanyModal>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BackButton from "../../components/BackButton";
import DeviceCertificateModal from "./partials/DeviceCertificateModal";
import DeviceCompanyModal from "./partials/DeviceCompanyModal";
import DeviceStatusModal from "./partials/DeviceStatusModal";
import Services from "./partials/Services";

export default {
  name: "DeviceView",
  components: {
    BackButton,
    DeviceCertificateModal,
    DeviceCompanyModal,
    DeviceStatusModal,
    Services,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      device: null,
      services: [],
      company: null,
      calibrationCertificateUrl: null,
      showDeviceStatusModal: false,
      showCertificateModal: false,
      showSystemOwnerModal: false,
      showAssignedToModal: false,
    };
  },
  computed: {
    ...mapGetters(["findDeviceStatusByUri", "userHasRoleSuperAdmin"]),
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getDevice"]),
    load() {
      this.loading = true;

      this.getDevice(this.id).then(async (device) => {
        device.status = this.findDeviceStatusByUri(device.status["@id"]);

        if (device.calibrationCertificates.length > 0) {
          const certificate = device.calibrationCertificates.slice(-1)[0];
          this.calibrationCertificateUrl = certificate.filePath;
        }

        this.device = device;

        this.loading = false;
      });
    },
  },
};
</script>
