<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ $t("status_change") }}
      </div>
    </div>
    <div class="card-content">
      <form class="mt-2" @submit.prevent="submit">
        <b-field :label="$tc('status')" label-for="status" horizontal>
          <b-select id="status" v-model="status" required>
            <option
              v-for="d in deviceStatusesOrderedByName"
              :key="d.id"
              :value="d['@id']"
            >
              {{ d.name }}
            </option>
          </b-select>
        </b-field>

        <b-button
          type="is-primary"
          native-type="submit"
          class="mt-5"
          icon-left="content-save"
          :loading="submitting"
        >
          {{ $t("save") }}
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      submitting: false,
      status: this.device.status["@id"],
    };
  },
  computed: {
    ...mapGetters(["deviceStatusesOrderedByName"]),
  },
  methods: {
    ...mapActions(["updateDevice"]),
    submit() {
      this.submitting = true;
      this.updateDevice({
        id: this.device.id,
        device: {
          status: this.status,
        },
      })
        .then(() => {
          this.$emit("submit");
          this.$parent.close();
        })
        .catch(() => {
          this.submitting = false;
        });
    },
  },
};
</script>
